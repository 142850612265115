import React from 'react'
import Title from '../components/title'
import ExpandCollapse from '../components/expandCollapse'
import NewLayout from '../layout/newLayout'
import styles from '../styles/scss/pages/faqs.module.scss'
import groupBy from 'lodash/groupBy'
import _map from 'lodash/map'
import PageContents from '../components/pageContents'
import SEO from "../components/seo"
import { supSub } from "../services/util"

const Faq = ({ pageContext }) => {

    var grouped = _map(groupBy(pageContext.faqs, (item => {return item.category.id})), (value) => {return value})
    const pg = pageContext.page

    return (
        <NewLayout>
        {pg.seo.title && <h1 style={{display:'none'}}>{pg.seo.title}</h1>}
        <SEO page={pg} />
        <div className='d-flex flex-column flex-fill'>
            <div className={styles.container}>

                <PageContents content={pageContext.page.content} />

                {grouped.map(group => (
                    <div className={styles.category} key={group[0].category.id}>
                        <Title alignLeft style={{ marginBottom: '20px' }} value={group[0].category.name} />
                        {group.map(item => {
                            return <ExpandCollapse style={{ color: 'white' }} key={item.id} title={item.question}>
                                <p dangerouslySetInnerHTML={{__html: supSub(item.answer) }} />
                            </ExpandCollapse>
                        })}
                    </div>
                ))}

            </div>
        </div>
        </NewLayout>
    )
}

export default Faq
